import React from "react";
import Text from "./Text";

const SmartText = ({
  text,
  length = 20,
  style,
  readMoreColor = "blue",
  lang = "He",
}) => {
  const [showLess, setShowLess] = React.useState(true);

  const readMore = {
    view: { He: "קרא", En: "View" },
    more: { He: "עוד", En: "more" },
    less: { He: "פחות", En: "less" },
  };

  if (!text) return "";
  if (text.length < length) {
    return <Text {...style} content={text} />;
  }

  return (
    <div {...style}>
      <Text
        {...style}
        content={showLess ? `${text.slice(0, length)}...` : text}
      />
      <a
        style={{
          color: readMoreColor,
          cursor: "pointer",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
          paddingTop: "3px",
        }}
        onClick={() => setShowLess(!showLess)}
      >
        &nbsp;{readMore["view"][lang]}{" "}
        {showLess ? readMore["more"][lang] : readMore["less"][lang]}
      </a>
    </div>
  );
};
export default SmartText;
